import { CaretDownIcon, CheckIcon } from "@radix-ui/react-icons";
import { UseFormReturn } from "react-hook-form";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import HorizontalGradientLine from "@/components/layout/HorizontalGradientLine";
import { t } from "i18next";
import { FieldValues } from "react-hook-form/dist/types/fields";
import { FieldPath } from "react-hook-form/dist/types";
import { removeDuplicatesByKey } from "@/util/unique";
import { Fragment, useMemo, useState } from "react";

export interface GenericComboBoxOption {
  group?: string;
  label: string;
  description?: string;
  value: string;
}

export const GenericComboBox = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  form,
  name,
  label,
  options,
  placeholder,
  className,
}: {
  form: UseFormReturn<T>;
  name: TName;
  label?: string;
  options: GenericComboBoxOption[];
  placeholder?: string;
  className?: string;
}) => {
  let [open, setOpen] = useState<boolean>(false);

  let groups = useMemo(() => {
    return removeDuplicatesByKey(
      options.map((o) => o.group || ""),
      (s) => s,
    );
  }, [options]);

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {label && (
            <FormLabel>
              {label}
              <FormMessage />
            </FormLabel>
          )}
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "w-full justify-between bg-transparent",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  <span className={"w-64 truncate text-left"}>
                    {field.value
                      ? options.find((option) => option.value === field.value)
                          ?.label
                      : placeholder}
                  </span>
                  <CaretDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="w-full p-0"
              style={{ width: "var(--radix-popover-trigger-width)" }}
            >
              <Command>
                <CommandInput
                  placeholder={placeholder || t("common.search")}
                  className="h-9"
                />
                <CommandList>
                  <CommandEmpty>{t("common.noDataResults")}</CommandEmpty>
                  {groups.flatMap((g) => {
                    let groupOptions = options.filter(
                      (o) => (o.group || "") == g,
                    );

                    return [
                      <CommandGroup
                        key={g}
                        heading={
                          <span className={"text-lg text-foreground"}>{g}</span>
                        }
                      >
                        {groupOptions.map((option, index) => (
                          <GenericComboboxItem
                            key={option.value}
                            index={index}
                            selected={field.value}
                            option={option}
                            onSelect={(value) => {
                              form.setValue(name, value as any);
                              setOpen(false);
                            }}
                          />
                        ))}
                      </CommandGroup>,
                    ];
                  })}
                </CommandList>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

const GenericComboboxItem = <
  T extends FieldValues = FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>(props: {
  option: GenericComboBoxOption;
  index?: number;
  selected: string;
  onSelect: (value: string) => void;
}) => {
  return (
    <CommandItem
      value={props.option.label}
      key={props.option.value}
      onSelect={() => {
        props.onSelect(props.option.value);
      }}
      className={cn(
        (props.index || 0) % 2 === 0 && "bg-muted-foreground/5",
        "py-4",
      )}
    >
      <HorizontalGradientLine />

      <span className={"ml-4 flex w-64 flex-col text-left"}>
        {props.option.label}
        {props.option.description && (
          <span className={"text-xs text-muted-foreground"}>
            {props.option.description}
          </span>
        )}
      </span>

      <CheckIcon
        className={cn(
          "ml-auto h-4 w-4",
          props.selected == props.option.value ? "opacity-100" : "opacity-0",
        )}
      />
    </CommandItem>
  );
};
